<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Käyttäjän asetukset</div>

    <v-card class="mt-2">
      <v-card-title>Käyttäjän tiedot / kirjautumistunnukset</v-card-title>
      <v-container>
        <v-form ref="formUser">
          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.name"
                label="Nimi"
                :rules="validations.required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.email"
                label="Käyttäjätunnus / Email"
                :rules="validations.required.concat(validations.email)"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field v-model="user.social" label="Hetu" dense outlined></v-text-field>
            </v-col>
          </v-row>

          <v-divider class="mb-2"></v-divider>

          <h3 class="mb-1">Lisätiedot</h3>

          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field v-model="user.company" label="Yritys" dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field v-model="user.address" label="Osoite" dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.zipCode"
                label="Postinumero"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.city"
                label="Postitoimipaikka"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.phone"
                label="Puhelinnumero"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.companySite"
                label="Yrityksen sivusto"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="user.workTitle"
                label="Tehtävänimike / titteli"
                placeholder="LKV, LVV, KED, PARTNER..."
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>Asetukset</h3>

          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-radio-group
                v-model="user.signForOthers"
                :mandatory="true"
                style="margin-top: 0px"
                hide-details
                label="Allekirjoita oletuksena valtakirjalla"
              >
                <v-radio label="Kyllä" :value="true"></v-radio>
                <v-radio label="Ei" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-select
                v-model.number="user.defaultTableRows"
                :items="globalValues.itemsPerPage"
                v-only-numbers
                v-prevent-paste
                :rules="validations.required.concat(validations.positiveNumber)"
                label="Taulukkorivien oletusmäärä"
                suffix="kpl"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row dense class="mt-2">
          <v-col>
            <v-btn color="info" @click="updateUserInformation">Tallenna käyttäjätiedot</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- Password -->
    <v-card class="mt-2">
      <v-card-title>Päivitä salasana</v-card-title>
      <v-container>
        <v-form ref="passwordForm">
          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="password"
                type="password"
                label="Uusi salasana"
                :rules="validations.required.concat(checkPassword())"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="passwordConfirm"
                type="password"
                label="Toista salasana"
                :rules="validations.required.concat(isEqual)"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model="currentPassword"
                type="password"
                label="Nykyinen salasana"
                :rules="validations.required"
                dense
                outlined
              ></v-text-field>
              <small> Päivittääksesi salasanasi, sinun tulee antaa myös nykyinen salasanasi </small>
            </v-col>
          </v-row>

          <v-btn color="info" class="mt-1" @click="updateMyPassword">Päivitä salasana</v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";
import validations from "@/validations";
import globalValues from "@/configs/globalValues";

export default {
  title: "Käyttäjän asetukset",

  mixins: [mixins],

  data: (vm) => {
    return {
      user: {
        email: "",
        name: "",
        social: "",
        company: "",
        companySite: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        workTitle: "",
        signForOthers: false,
        defaultTableRows: 15,
      },
      password: "",
      passwordConfirm: "",
      currentPassword: "",
      isEqual: [(v) => v === vm.password || "Salasanat eivät vastaa toisiaan"],
      validations,
      globalValues,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
  },

  watch: {
    currentUser(val) {
      this.user = { ...val };
    },
  },

  created() {
    this.user = { ...this.$store.state.account.currentUser };
  },

  methods: {
    ...mapActions("userProfile", ["updateUserData", "updatePassword"]),
    ...mapActions("account", ["getCurrentUser"]),

    checkPassword() {
      return [
        (v) => (v && v.length >= 6) || "Salasanan tulee olla vähintään 6 merkkiä pitkä",
        (v) => /[A-Z]/.test(v) || "Salasanan tulee sisältää vähintään yksi iso kirjain",
        (v) => /\d/.test(v) || "Salasanan tulee sisältää vähintään yksi numero",
      ];
    },

    async updateUserInformation() {
      if (this.$refs.formUser.validate()) {
        try {
          await this.updateUserData(this.user);
          await this.getCurrentUser();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async updateMyPassword() {
      if (this.$refs.passwordForm.validate()) {
        try {
          await this.updatePassword({
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            currentPassword: this.currentPassword,
          });

          this.$refs.passwordForm.reset();
        } catch (err) {
          this.$refs.passwordForm.reset();
        }
      }
    },
  },
};
</script>

<style scoped></style>
